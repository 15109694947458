<template>
  <vuestic-widget v-if="user" :loading="loading">
    <template slot="header">
      <span class="title">SMS Registration for <b class="text-primary">{{ number | phone }}</b></span>
    </template>
    <div class="verification-page">
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="tollfreeForm">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="row mt-3">
            <div v-if="data.status" class="col-md-12 my-3">
              <div class="alert alert-primary d-block" :class="{ 'alert-danger': data.status === 'twilio_rejected' }">
                <span class="d-block text-primary font-weight-bold">Status: {{ data.statusStr }} <i
                    v-if="statusInProgress" class="fa fa-refresh fa-spin"></i></span>
                <br />
                <span v-if="statusInProgress" class="d-block font-weight-bold">This registration is
                  under review, and may take several weeks to complete. You may check back on
                  this registration status in a couple of days.</span>
                <span v-if="data.status === 'twilio_rejected'">
                  SMS Registration is failed due to unexpected reason. Please contact admin at <a
                    :href="`mailto:${agencyEmail}`" target="_blank" class="font-weight-bold text-decoration-underline">{{
                      agencyEmail }}</a> for more information!
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <text-input name="BusinessName" label="Business Name" v-model="data.businessName" validate="required"
                :disabled="isInputDisabled" />
            </div>
            <div class="col-md-6">
              <text-input name="Website" label="Website" v-model="data.businessWebsite" validate="required"
                :disabled="isInputDisabled" help="Website that you are going to add widget/form for this number to." />
            </div>
            <div class="col-md-6">
              <label class="lelabel">Estimated monthly volume</label>
              <multiselect v-model="volumeSelected" :options="volumeList" :multiple="false" placeholder="Select Volume"
                track-by="value" label="name" :show-labels="false" :close-on-select="true" :clear-on-select="false"
                :preserve-search="true" :allowEmpty="false" :disabled="isInputDisabled">
              </multiselect>
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <router-link class="btn btn-primary btn-danger mr-2" :to="{ name: 'business.numbers' }">
                <span>Back <i class="fa fa-arrow-left"></i></span>
              </router-link>
              <button v-if="isInputDisabled" type="button" class="btn btn-primary" :disabled="processing"
                @click="onRefresh">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Refresh</span>
              </button>
              <button v-else class="btn btn-primary" :disabled="processing">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </vuestic-widget>
</template>
    
<script>
export default {
  data() {
    return {
      loading: false,
      processing: false,
      installId: undefined,
      number: null,
      data: {},
      volumeSelected: undefined,
      volumeList: [
        { name: '100', value: '100' },
        { name: '1,000', value: '1,000' },
        { name: '10,000', value: '10,000' },
        { name: '100,000', value: '100,000' },
        { name: '250,000', value: '250,000' },
        { name: '500,000', value: '500,000' },
        { name: '750,000', value: '750,000' },
        { name: '1,000,000', value: '1,000,000' },
        { name: '5,000,000', value: '5,000,000' },
        { name: '10,000,000+', value: '10,000,000+' },
      ]
    }
  },

  mounted() {
    this.installId = this.$route.params.id
    if (!this.installId || !this.hasMainProfile) {
      this.$router.push({ name: 'business.numbers' });
    } else {
      this.loadData();
    }
  },

  watch: {
    'volumeSelected': {
      handler: function (newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          this.data.messageVolume = newValue.value
        }
      }
    },
  },

  methods: {
    onRefresh() {
      this.loadData()
    },

    initData() {
      this.volumeSelected = this.volumeList.find(item => item.value === this.data.messageVolume)
    },

    loadData() {
      this.loading = true
      let params = {
        install_id: this.installId,
      }
      this.$store
        .dispatch('install/getTollfreeVerification', params)
        .then((res) => {
          this.data = res.data;
          this.number = res.install;
          if (!this.isTollfreeAttribute) {
            Vue.$toast.open({
              message: 'You have no access to this page.',
              type: 'error',
            })
            this.$router.push({ name: 'business.numbers' });
          }
          this.initData();
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },

    submit() {
      if (!this.data.messageVolume) {
        Vue.$toast.open({
          message: 'Please select Message Volumn per month.',
          type: 'error',
        })
        return;
      }
      this.processing = true
      let params = {
        install_id: this.installId,
        ...this.data,
      }
      this.$store
        .dispatch('install/createTollfreeVerification', params)
        .then((res) => {
          this.processing = false
          this.loadData()
        })
        .catch((err) => {
          console.log(err)
          this.processing = false
        })
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },
    agency() {
      return this.user && this.user.agency
    },
    agencyEmail() {
      return this.agency && this.agency.email
    },
    hasMainProfile() {
      return this.agency && this.agency.tw_profile_sid
    },
    isTollfreeAttribute() {
      return this.number && this.number[2] == 8;
    },
    statusInProgress() {
      return this.data.status && ['in_review', 'pending_review'].includes(this.data.status)
    },
    isInputDisabled() {
      return this.data.status && !['twilio_rejected'].includes(this.data.status)
    }
  }
}
</script>
    
<style scoped lang="scss">
.verification-page {
  max-width: 1024px;
}</style>